import './cta-teaser.scss';
class CtaTeaser {
    constructor (el) {
        this.$ctaTeaser = el;
        this.$parallaxEl = this.$ctaTeaser.querySelector('[data-cta-teaser="parallax"]');
        this.$parallaxHl = this.$ctaTeaser.querySelector('[data-cta-teaser="parallax-headline"]');
    }

    init () {
        const ctaTeaserHeight = this.$ctaTeaser.getBoundingClientRect().height;
        // const moveCopy = ctaTeaserHeight - (this.$parallaxEl.getBoundingClientRect().top - this.$ctaTeaser.getBoundingClientRect().top);
        // const moveHeadline = ctaTeaserHeight - (this.$parallaxHl.getBoundingClientRect().top - this.$ctaTeaser.getBoundingClientRect().top);
        // this.$parallaxEl.style.top = `${moveCopy}px`;
        // this.$parallaxHl.style.top = `${moveHeadline}px`;
        console.log('this.$ctaTeaser.offsetTop', this.$ctaTeaser.getBoundingClientRect().top);
        console.log('this.$parallaxEl.offsetTop', this.$parallaxEl.getBoundingClientRect().top);
        console.log('this.$parallaxHl.offsetTop', this.$parallaxHl.getBoundingClientRect().top);
        console.log('diff', ctaTeaserHeight - (this.$parallaxHl.getBoundingClientRect().top - this.$ctaTeaser.getBoundingClientRect().top));
        // this.$parallaxEl.style.top = '100px';
        this.parallax(this.$parallaxHl, '-');
        this.parallax(this.$parallaxEl, '-');

        window.addEventListener('scroll', () => {
            this.parallax(this.$parallaxHl, '-');
            this.parallax(this.$parallaxEl, '-');
        });
    }

    parallax (el, dir) {
        const y = window.innerHeight - this.$ctaTeaser.getBoundingClientRect().top - this.$ctaTeaser.getBoundingClientRect().height / 2;
        const posY = (this.$ctaTeaser.offsetTop / this.$ctaTeaser.getBoundingClientRect().height).toFixed(2) / 10;
        console.log('posY:', posY);
        // console.log('window.innerHeight:', window.innerHeight);
        // console.log('this.$ctaTeaser.getBoundingClientRect().top', this.$ctaTeaser.getBoundingClientRect().top);
        console.log('y:', y);
        console.log('parallax it!!!', y);
        el.style.transform = 'translate3d(0,' + (posY * y) + 'px ,0)';
    }
}

export { CtaTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $ctaTeasers = $context.querySelectorAll('[data-cta-teaser="root"]');

        for (let i = 0; i < $ctaTeasers.length; i++) {
            // const $ctaTeaser = new CtaTeaser($ctaTeasers[i]);
            // $ctaTeaser.init();
        }
    }
});
